import React, { useContext, useState, useMemo, useCallback } from "react";
import { PriceDataContext } from "../../context/PriceDataContext";
import PriceDataModel from "../../models/priceView/PriceData.model";
import "./ActionTableCell.css";
import { getImagePath } from "../../util/util";
import {
  APPROVED,
  PENDING,
  REJECTED,
} from "../../util/constants/stringConstants";

interface ActionTableCellProps {
  dataItem: PriceDataModel;
  mode: "approve" | "deny";
}

// Add this type definition
type ActionConfig = {
  action: string;
  altText: string;
  whenPending: string;
  whenHovered: string;
  whenActive: {
    iconName: string;
    style: React.CSSProperties;
  };
  whenDisabled: string;
  className: string;
};

const actionConfig: Record<"deny" | "approve", ActionConfig> = {
  deny: {
    action: REJECTED,
    altText: "Deny",
    whenPending: "deny",
    whenHovered: "denyRed",
    whenActive: {
      iconName: "denyRed",
      style: {
        backgroundColor: "#FFDFDF",
      },
    },
    whenDisabled: "denyDisabled",
    className: "status-rejected",
  },
  approve: {
    action: APPROVED,
    altText: "Approve",
    whenPending: "checkmark",
    whenHovered: "checkmarkGreen",
    whenActive: {
      iconName: "checkmarkGreen",
      style: {
        backgroundColor: "#CAFFCA",
      },
    },
    whenDisabled: "checkmarkDisabled",
    className: "status-approved",
  },
};

// Helper functions for status checks
function isItemActive(latestStatus: string, modeAction: string): boolean {
  return latestStatus === modeAction;
}

function getDisabledIconName(isDisabled: boolean, config: ActionConfig): string {
  return isDisabled ? config.whenDisabled : "";
}

function getInteractionIconName(
  isActive: boolean,
  isHovered: boolean,
  config: ActionConfig
): string | undefined {
  if (isActive) {
    return config.whenActive.iconName;
  }
  if (isHovered) {
    return config.whenHovered;
  }
  return undefined;
}

function getActionIconName(
  dataItem: PriceDataModel,
  mode: keyof typeof actionConfig,
  isHovered: boolean,
  isDisabled: boolean,
  isActive: boolean
): string {
  const {
    priceData: { latestPriceGenPriceStatus },
  } = dataItem;
  const config = actionConfig[mode];

  const disabledIcon = getDisabledIconName(isDisabled, config);
  if (disabledIcon) return disabledIcon;

  const interactionIcon = getInteractionIconName(isActive, isHovered, config);
  if (interactionIcon) return interactionIcon;

  if (isItemActive(latestPriceGenPriceStatus, config.action)) {
    return config.whenActive.iconName;
  }

  return config.whenPending;
}

export const ActionTableCell: React.FC<ActionTableCellProps> = ({
  dataItem,
  mode,
}) => {
  const priceDataCtx = useContext(PriceDataContext);
  const { changeProposedPriceStatus } = priceDataCtx;

  const [isHovered, setIsHovered] = useState(false);

  const config = actionConfig[mode];

  const isDisabled =
    dataItem.priceData.latestPriceGenPriceStatus !== PENDING &&
    (dataItem.status !== APPROVED ||
      (dataItem.status === APPROVED && mode === "deny"));

  const isActive = !isDisabled && dataItem.status === config.action;

  const imageName = useMemo(
    () =>
      getActionIconName(
        dataItem,
        mode,
        isHovered,
        isDisabled,
        isActive
      ),
    [dataItem, mode, isHovered]
  );

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!isDisabled || dataItem.isManualPriceSet) {
        console.log("clicked", dataItem.isManualPriceSet);
        changeProposedPriceStatus(config.action, dataItem.productId);
      }
    },
    [dataItem.productId, changeProposedPriceStatus, dataItem.isManualPriceSet]
  );

  return (
    <td
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`action-td bordered-cell ${
        isActive ? config.className : ""
      } ${isDisabled ? "disabled" : ""}`}
      style={isActive ? config.whenActive.style : {}}
      onClick={onClick}
    >
      <button className={"action-btn"} onClick={onClick} disabled={isDisabled}>
        <img src={getImagePath(imageName)} alt={config.altText} />
      </button>
    </td>
  );
};
