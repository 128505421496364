import { useMutation } from "@tanstack/react-query";
import { ManualPriceEntry, updateManualPrice } from "../../api/manualPricing";

export const useUpdateManualPricing = () => {
  return useMutation({
    mutationFn: (manualPrices: ManualPriceEntry[]) =>
      updateManualPrice(manualPrices),
    onSuccess: (_, variables) => {
      console.debug(
        "manualPricing useUpdateManualPricing onSuccess",
        variables
      );
    },
  });
};
