import { useMutation } from "@tanstack/react-query";

import { updateStatusForStoreProduct } from "../../api/proposedPrices";
import IUpdateStatus from "../../models/priceView/IUpdateStatus";

export const useUpdateStatusForStoreProduct = () => {
  return useMutation({
    mutationFn: ({
      updateStatusArray,
    }: {
      updateStatusArray: IUpdateStatus[];
    }) => updateStatusForStoreProduct(updateStatusArray),
  });
};
