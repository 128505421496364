import { useMutation } from "@tanstack/react-query";

import { callForceGeneratePrice } from "../../api/proposedPrices";

export const useForceGenerate = () => {
  return useMutation({
    mutationFn: ({
      fpisStoreId,
      seiId,
      manualReview,
    }: {
      fpisStoreId: string;
      seiId: string;
      manualReview: boolean;
    }) => callForceGeneratePrice(fpisStoreId, seiId, manualReview),
  });
};
