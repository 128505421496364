import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import {
  HistoryTabDataContext,
  ProductEntry,
} from "../../../context/HistoryTabContext";
import { PriceDataContext } from "../../../context/PriceDataContext";
import "./HistoryTab.css";
import {
  IAllHistory,
  IHistoryMessageColumns,
} from "../../../models/history/IProductHistory";
import * as util from "../../../util/util";
import { setToEndOfDay, setToStartOfDay } from "../../../util/util";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Handlebars from "handlebars";
import { Backdrop, CircularProgress } from "@mui/material";
import { fetchHistoryTemplates } from "../../../api/mongoEndpoints";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import DataCell from "./DataCell";

const filterTypeMap = {
  proposedPriceHistory: "Proposed Price",
  strategyHistory: "Strategy",
  competitorPriceHistory: "Competitor Price",
  validationHistory: "Controls & Validations",
};

function getColumns(
  data: IHistoryMessageColumns[]
): MRT_ColumnDef<IHistoryMessageColumns>[] {
  const historyTypeCounts = data.reduce((acc, item) => {
    acc[item.historyType] = (acc[item.historyType] || 0) + 1;
    return acc;
  }, {});

  const uniqueHistoryTypes = Object.keys(historyTypeCounts);

  return [
    {
      accessorKey: "auditDate",
      header: "Date",
      size: 100,
      enableSorting: true,
      filterVariant: "date-range",
      grow: false,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        let date: Date;

        if (typeof value === "string" || typeof value === "number") {
          date = new Date(value);
        } else if (value instanceof Date) {
          date = value;
        } else {
          console.error("Invalid date value:", value);
          return "";
        }

        return date
          .toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          .replace(/,/g, "");
      },
    },
    {
      accessorKey: "historyType",
      header: "History Type",
      size: 50,
      Cell: ({ renderedCellValue }) => (
        <HistoryTypeCell renderedCellValue={renderedCellValue} />
      ),
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: uniqueHistoryTypes.map((type) => ({
        text: `${filterTypeMap[type]} (${historyTypeCounts[type]})`,
        value: type,
      })),
    },
    {
      accessorKey: "userName",
      header: "Init/Approved By",
      enableSorting: true,
      size: 15,
    },

    {
      accessorKey: "message",
      header: "Message",
      enableSorting: false,
      size: 225,
    },
    {
      accessorKey: "data",
      Cell: ({ cell }) => <DataCell data={cell.getValue()} />,
      header: "Data",
      size: 100,
    },
  ];
}

const HistoryTab = () => {
  const { selectedPriceData } = useContext(PriceDataContext);
  const { historyData, dateRange, setDateRange, isLoading } = useContext(
    HistoryTabDataContext
  );

  const [transformedHistory, setTransformedHistory] = useState({});
  const [selectedHistory, setSelectedHistory] = useState<
    IHistoryMessageColumns[]
  >([]);
  const [templates, setTemplates] = useState({});
  const [columnSizing, setColumnSizing] = useLocalStorage(
    "historyTab_columnSizing",
    []
  );

  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
    const loadTemplates = async () => {
      let fetchedTemplates = sessionStorage.getItem("historyTemplates");
      if (fetchedTemplates) {
        console.debug("History templates found in session storage.");
        setTemplates(JSON.parse(fetchedTemplates));
        return;
      }

      console.debug("No history templates found. Fetching from server...");
      try {
        fetchedTemplates = await fetchHistoryTemplates();
        sessionStorage.setItem(
          "historyTemplates",
          JSON.stringify(fetchedTemplates[0])
        );
        setTemplates(fetchedTemplates[0]);
        console.debug(
          "Fetched history templates:",
          JSON.stringify(fetchedTemplates[0])
        );
      } catch (error) {
        console.error("Failed to load history templates:", error);
        setTemplates({});
      }
    };
    loadTemplates().then();
  }, []);

  useEffect(() => {
    if (!historyData || !templates) {
      console.debug("No history templates available. Skipping transformation.");
      // setIsHistoryLoading(false);
      return;
    } else {
      // console.log(historyData);
    }
    setTransformedHistory(transformHistoryData(historyData));
  }, [templates, historyData]);

  useEffect(() => {
    // change selectedHistory to the selected history data
    if (
      !selectedPriceData ||
      !transformedHistory[selectedPriceData.priceData.productId]
    ) {
      console.debug(
        "No price data selected or no transformed history data available for selected product"
      );
      setSelectedHistory([]);
      return;
    }
    console.debug(
      "Setting selected history",
      selectedPriceData.priceData.productId
    );
    setSelectedHistory(
      transformedHistory[selectedPriceData.priceData.productId]
    );
  }, [selectedPriceData, transformedHistory]);
  useEffect(() => {
    setColumnFilters([]);
    setDateRange({ fromDate: null, toDate: null });
  }, [selectedPriceData]);

  const columns = useMemo<MRT_ColumnDef<IHistoryMessageColumns>[]>(
    () => getColumns(selectedHistory),
    [selectedHistory]
  );

  function generateMessageFromTemplate(data, messageTemplate) {  
    // console.debug("Generating message from template");
    
    const template = Handlebars.compile(messageTemplate);
    return template(data);
  }

  function selectAndProcessTemplate(data: any, historyType: string) {
    const templateInfo = templates[historyType];
    // console.debug(
    //   "Template info:",
    //   templateInfo,
    //   "for history type:",
    //   historyType
    // );

    let dataString = getDataString(data, templateInfo) || "";
    let messageTemplate = templateInfo.messageTemplate
    if (data.strategies === "ManualPrice") {
      messageTemplate = "Manual price of {{proposedPrice}} set for {{productDisplayName productId}} is {{approveStatus}}";
    }
    if(historyType === 'proposedPriceHistory' && data.approveStatus === 'BLOCKED'){
      if (
          data.controlsAndValidation.some(
              (validation) =>
                  validation.validationType === "MarketMoveScheduler" &&
                  validation.validationFailed === true
          )
      ) {
        messageTemplate =
            "Proposed price {{productDisplayName productId}} is {{approveStatus}} - Store in Active Market Move";
      } else {
        const failedValidation = data.controlsAndValidation.find(
            (cv) => cv.validationFailed
        );
        messageTemplate = failedValidation
            ? `Proposed price {{productDisplayName productId}} is {{approveStatus}} - ${failedValidation.validationType} validation FAILED`
            : `Proposed price {{productDisplayName productId}} is {{approveStatus}} - Linked product validation failure`;
      }
    }

    return {
      message: generateMessageFromTemplate(data, messageTemplate),
      changeType: getMutationType(data, templateInfo) || "N/A",
      data: dataString,
      userName: Handlebars.compile(templateInfo.userField)(data),
    };
  }

  function getDataString(data: any, templateInfo: any): string {
    if (templateInfo.dataTemplate) {
      const template = Handlebars.compile(templateInfo.dataTemplate);
      return template(data);
    }
    return "";
  }

  function getMutationType(data: any, templateInfo: any): string | null {
    try {
      const changeType = templateInfo.changeType;
      if (changeType.type === "field") {
        const fieldPath = changeType.value.split(".");
        return fieldPath.reduce((acc, field) => acc[field], data);
      }
      return changeType.value || "N/A";
    } catch (error) {
      console.error("Failed to get mutation type:", error);
      return "N/A";
    }
  }

  function processHistoryItems(
    historyItems: any[],
    historyType: string,
    auditTimeKey: string
  ): ProductEntry[] {
    // filter items with no audit date
    return historyItems.map((item) => {
      const processedTemplate = selectAndProcessTemplate(item, historyType);
      return {
        auditDate: (historyType === 'proposedPriceHistory' && item.approveStatus === 'BLOCKED') ? util.parseDate(item['creationDate']) : util.parseDate(item[auditTimeKey]),
        changeType: processedTemplate.changeType.toUpperCase(),
        userName: processedTemplate.userName,
        historyType: historyType,
        message: processedTemplate.message,
        data: processedTemplate.data,
      };
    });
  }

  function transformHistoryData(historyData: IAllHistory) {
    const transformedData: { [productId: string]: ProductEntry[] } = {};

    // console.debug(`Transforming history data: ${JSON.stringify(historyData)}`);

    Object.entries(historyData.histories).forEach(
      ([productId, productInfo]) => {
        const historyTypes = [
          "proposedPriceHistory",
          "competitorPriceHistory",
          "strategyHistory",
          "validationHistory",
        ];

        historyTypes.forEach((historyType) => {
          let historyItems = productInfo[historyType];
          if (!historyItems) {
            console.debug(
              `No history data for history type: ${historyType} for product ID: ${productId}`
            );
            return;
          }

          // console.debug(
          //   `Processing history items for history type: ${historyType}. Template: ${JSON.stringify(
          //     templates
          //   )}`
          // );
          /**
           * FP - 21016
           * Added filter in history items in proposedPriceHistory.
           * If prices are BLOCKED show for only cases where it is BLOCKED by MarketmoveScheduler
           */
          if(historyType === 'proposedPriceHistory' ){
            historyItems = historyItems.filter((item) => item[templates[historyType].dateField] || 
            (item.approveStatus=='BLOCKED'));
          }else{
            historyItems = historyItems.filter((item) => item[templates[historyType].dateField]);
          }
          let processedItems: ProductEntry[] = [];
          try {
            processedItems = processHistoryItems(
              historyItems,
              historyType,
              templates[historyType].dateField
            );
            // remove processed items of type "StrategyHistory" that have "UNCHANGED" in data
            if (historyType === "strategyHistory") {
              processedItems = processedItems.filter(
                (item) => !item.data.includes("UNCHANGED")
              );
            }
          } catch (e) {
            console.error(
              `Failed to process history items for product ID: ${productId} and history type: ${historyType}`,
              e
            );
          }
          transformedData[productId] = [
            ...(transformedData[productId] || []),
            ...processedItems,
          ];
        });
        console.debug(
          `Transformed history data for product ID: ${productId}: ${transformedData[productId].length} items`
        );
      }
    );

    return transformedData;
  }

  const isHistoryLoading = () => {
    // Assuming loading is considered complete when both templates and historyData are not empty
    return isLoading || templates === null || historyData === null;
  };

  // Main logic for handling date filter changes
  const handleDateFilterChange = (newColumnFiltersFn) => {
    let newColumnFilters: { id: string; value: any }[] =
      newColumnFiltersFn(columnFilters);
    if (!newColumnFilters) return;

    let auditDateFilter: Date[] | null[] = newColumnFilters.find(
      (f) => f.id === "auditDate"
    )?.value;

    if (auditDateFilter) {
      let [minDate, maxDate] = auditDateFilter || [null, null];

      const { minDate: newMinDate, maxDate: newMaxDate } = validateDateRange(
        columnFilters.find((f) => f.id === "auditDate")?.value,
        auditDateFilter,
        minDate,
        maxDate
      );

      newColumnFilters = newColumnFilters.map((f) =>
        f.id === "auditDate"
          ? {
              id: "auditDate",
              value: [newMinDate, newMaxDate],
            }
          : f
      );

      setDateRange({ fromDate: newMinDate, toDate: newMaxDate });
    } else {
      setDateRange({ fromDate: null, toDate: null });
    }

    setColumnFilters(newColumnFilters);
  };

  function validateDateRange(
    prevAuditDateFilter,
    auditDateFilter,
    minDate,
    maxDate
  ): { minDate: Date; maxDate: Date } {
    const today = new Date();

    if (minDate && !maxDate) {
      // If minDate is set and maxDate is not set, set maxDate to today
      maxDate = setToEndOfDay(today);
      console.debug(`minDateSet - Setting maxDate to ${maxDate}`);
    }

    if (!minDate && maxDate) {
      // If minDate is not set and maxDate is set, allow any minDate
      minDate = null;
      console.debug(`maxDateSet - Allowing any minDate`);
    }

    if (
      prevAuditDateFilter &&
      prevAuditDateFilter[0] === minDate &&
      prevAuditDateFilter[1] !== maxDate
    ) {
      // If minDate is unchanged and maxDate is changed, keep the previous maxDate
      maxDate = prevAuditDateFilter[1];
      console.debug(`minDateUnchanged - Keeping previous maxDate: ${maxDate}`);
    }

    if (minDate && minDate > today) {
      minDate = setToStartOfDay(today);
      console.debug(`minDateGreaterThanToday - Setting minDate to ${minDate}`);
    }
    if (maxDate && maxDate > today) {
      maxDate = setToEndOfDay(today);
      console.debug(`maxDateGreaterThanToday - Setting maxDate to ${maxDate}`);
    }

    if (minDate && maxDate && minDate > maxDate) {
      // If minDate is greater than maxDate, swap their values
      [minDate, maxDate] = [maxDate, minDate];
      console.debug(
        `minDateGreaterThanMaxDate - Swapping minDate and maxDate: minDate: ${minDate}, maxDate: ${maxDate}`
      );
    }

    return {
      minDate,
      maxDate,
    };
  }

  const table = useMaterialReactTable({
    data: selectedHistory || [],
    columns: columns,
    enablePagination: false,
    enableSorting: true,
    enableFilters: true,
    initialState: {
      sorting: [{ id: "auditDate", desc: true }],
      showColumnFilters: false,
    },
    positionToolbarAlertBanner: "none",
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnActions: true,
    enableHiding: true,
    enableFacetedValues: true,
    columnFilterDisplayMode: "popover",
    enableColumnResizing: true,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "53vh" },
    },
    onColumnFiltersChange: handleDateFilterChange,
    // enableColumnDragging: true,
    // enableColumnOrdering: true,
    state: {
      columnFilters,
      // columnOrder,
      columnSizing,
    },
    // onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    layoutMode: "grid",
  });

  return (
    <div className="history-tab-inner">
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isHistoryLoading()}
      >
        {"Loading..."} <CircularProgress color="inherit" />
      </Backdrop>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </div>
  );
};
const HistoryTypeCell = ({ renderedCellValue }) => (
  <>{filterTypeMap[renderedCellValue]}</>
);

export default HistoryTab;
