import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import useDialogStore from "../../store/useDialogStore";

const GlobalDialog = () => {
  const {
    isOpen, content, actions, closeDialog,
    acceptCallback, discardCallback,
    acceptButtonText = "Accept", discardButtonText = "Discard",
  } = useDialogStore();

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="xs">
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {actions || (
          <>
            <Button onClick={discardCallback} color="error">{discardButtonText}</Button>
            <Button onClick={acceptCallback} color="success">{acceptButtonText}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDialog;
