import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./PriceInput.css";
import { PriceDataContext } from "../../../context/PriceDataContext";
import PriceEdit from "./PriceEdit";
import AdjustPriceButtons from "./AdjustPriceButtons";
import PriceDisplay from "./PriceDisplay";
import useDialogStore from "../../../store/useDialogStore";
import { Typography, Box, Stack } from "@mui/material";

interface PriceInputProps {
  productId: string;
}

const PriceInput: React.FC<PriceInputProps> = ({ productId }) => {
  const priceDataCtx = useContext(PriceDataContext);
  const { priceData, updateProposedPrice } = priceDataCtx;
  const dialogStore = useDialogStore();

  const [showInput, setShowInput] = useState(false);
  const [tempPrice, setTempPrice] = useState("");

  const priceDataItem = priceData.find((item) => item.productId === productId);
  const proposedPrice = useMemo(() => (
    priceDataItem?.proposedPrice || 0
  ), [priceDataItem?.proposedPrice]);

  const originalPrice = useMemo(() => (
    priceDataItem?.priceData.latestPriceGenPrice || 0
  ), [priceDataItem?.priceData.latestPriceGenPrice]);

  const isPending = priceDataItem?.priceData.latestPriceGenPriceStatus === "PENDING";
  const disabled = false;
  const inputBackgroundColor = isPending && !priceDataItem?.status ? "#F7951C" : "#B9BBC5";

  // Initial setup effects remain the same
  useEffect(() => {
    if (!priceDataCtx || !priceDataItem) return;
    setTempPrice(priceDataItem.proposedPrice ? priceDataItem.proposedPrice.toString() : "");
  }, [priceData]);

  useEffect(() => {
    if (!priceData.length) return;
    if (tempPrice === "") {
      setTempPrice(proposedPrice.toString());
    }
  }, [proposedPrice, tempPrice, priceData.length]);

  const ensureValidPrice = useCallback((value: string) => {
    let number = parseFloat(value);
    if (number <= 0 && priceDataItem) {
      number = parseFloat(originalPrice.toString());
    }
    return number.toFixed(3);
  }, [priceDataItem, originalPrice]);

  const needsPriceConfirmation = useCallback((newPrice: number) => {
    const priceDiff = Math.abs(newPrice - originalPrice);
    return priceDiff >= 0.50;
  }, [originalPrice]);

  const generatePriceChangeDialog = (originalPrice: number, newPrice: number) => {
    const priceDiff = Math.abs(newPrice - originalPrice);
    return (
      <Box>
        <Typography variant="h6" sx={{ mb: 1 }}>Please confirm this significant price change:</Typography>
        <Typography color="error.main" sx={{ mb: 2 }}>Price difference: <b>${priceDiff.toFixed(3)}</b></Typography>
        <Stack spacing={1}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Original price:</Typography>
            <Typography><b>${originalPrice.toFixed(3)}</b></Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>New price:</Typography>
            <Typography><b>${newPrice.toFixed(3)}</b></Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  const handlePriceUpdate = useCallback((newPrice: number | string, shouldCommit = false, isManualEntry = false) => {
    const validPrice = ensureValidPrice(newPrice.toString());
    const numericPrice = parseFloat(validPrice);

    if (shouldCommit && isManualEntry && needsPriceConfirmation(numericPrice)) {
      dialogStore.openDialog({
        content: generatePriceChangeDialog(originalPrice, numericPrice),
        acceptCallback: () => {
          setTempPrice(validPrice);
          updateProposedPrice(numericPrice, productId);
          setShowInput(false);
          dialogStore.closeDialog();
        },
        discardCallback: () => {
          setTempPrice(proposedPrice.toString());
          setShowInput(false);
          dialogStore.closeDialog();
        },
        acceptButtonText: "Confirm Change",
        discardButtonText: "Cancel"
      });
      return;
    }

    setTempPrice(validPrice);
    if (shouldCommit) {
      console.debug(`Committing price update for ${productId}`);
      updateProposedPrice(numericPrice, productId);
      setShowInput(false);
    }
  }, [ensureValidPrice, productId, updateProposedPrice, proposedPrice, needsPriceConfirmation, dialogStore]);

  const handleFinalizeInput = useCallback(() => {
    handlePriceUpdate(tempPrice, true, true);
  }, [tempPrice, handlePriceUpdate]);

  const onKeyDown = useCallback((e) => {
    if (e.key === "ArrowUp") {
      handlePriceUpdate((parseFloat(tempPrice) + 0.01).toFixed(3), false);
      e.preventDefault();
    } else if (e.key === "ArrowDown") {
      handlePriceUpdate((parseFloat(tempPrice) - 0.01).toFixed(3), false);
      e.preventDefault();
    } else if (e.key === "Enter") {
      handleFinalizeInput();
    } else if (e.key === "Escape") {
      handlePriceUpdate(proposedPrice.toString());
      setShowInput(false);
    }
  }, [tempPrice, proposedPrice, handleFinalizeInput, handlePriceUpdate]);

  if (!tempPrice) return <></>;
  
  return (
    <div
      className={`price-input ${disabled ? "disabled" : ""}`}
      style={{ borderColor: inputBackgroundColor }}
    >
      {showInput ? (
        <PriceEdit
          tempPrice={tempPrice}
          onBlur={handleFinalizeInput}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            const formattedValue = formatInputValue(e.target.value.toString());
            if (formattedValue !== "") {
              handlePriceUpdate(formattedValue);
            }
          }}
        />
      ) : (
        <>
          <PriceDisplay
            price={parseFloat(tempPrice)}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              !disabled && setShowInput(true);
            }}
          />
          <AdjustPriceButtons
            onIncrement={(e) => {
              e.stopPropagation();
              handlePriceUpdate((parseFloat(tempPrice) + 0.01).toFixed(3), true, false);
            }}
            onDecrement={(e) => {
              e.stopPropagation();
              if (parseFloat(tempPrice) <= 0) return;
              handlePriceUpdate((parseFloat(tempPrice) - 0.01).toFixed(3), true, false);
            }}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};

const formatInputValue = (value: string) => {
  const regex = /^-?\d*\.?\d{0,3}$/;
  if (RegExp(regex).exec(value)) {
    return value;
  }
  const number = parseFloat(value);
  return isNaN(number) ? "" : number.toFixed(3);
};

export default PriceInput;
