import apiClient from "../util/apiClient";
import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";

export interface ManualPriceEntry {
    seiStoreId: string;
    fpisStoreId: string;
    productId: string;
    productName: string;
    manualPrice: number;
    lastProposedPrice: number;
    userId: string;
}

export const updateManualPrice = async (manualPrices: ManualPriceEntry[]) => {
    const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceAdminMaster.name,
        constants.urlConstants.priceGenPriceAdminMaster.manualPrice
    );
    const response = await apiClient.post(serviceUrl, manualPrices);
    return response.data;
}