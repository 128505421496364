import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface DialogState {
  isOpen: boolean;
  content: React.ReactNode | null;
  actions: React.ReactNode | null;
  acceptCallback?: () => void;
  discardCallback?: () => void;
  acceptButtonText?: string;
  discardButtonText?: string;
  openDialog: (options: OpenDialogOptions) => void;
  closeDialog: () => void;
}

interface OpenDialogOptions {
  content: React.ReactNode;
  actions?: React.ReactNode;
  acceptCallback?: () => void;
  discardCallback?: () => void;
  acceptButtonText?: string;
  discardButtonText?: string;
}

const useDialogStore = create<DialogState>()(
  immer((set, get) => ({
    isOpen: false,
    content: null,
    actions: null,
    acceptCallback: () => get().closeDialog(),
    discardCallback: () => get().closeDialog(),
    acceptButtonText: "Accept",
    discardButtonText: "Discard",
    openDialog: ({
      content,
      actions,
      acceptCallback = () => get().closeDialog(),
      discardCallback = () => get().closeDialog(),
      acceptButtonText,
      discardButtonText,
    }: OpenDialogOptions) =>
      set({
        isOpen: true,
        content,
        actions,
        acceptCallback,
        discardCallback,
        acceptButtonText,
        discardButtonText,
      }),
    closeDialog: () => set({ isOpen: false }),
  }))
);

export default useDialogStore;
